import React, { useCallback, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { PanelStyles, Feature, FeatureWrapper } from './PanelStyles';
import OptimizedImage from '../../utils/OptimizedImage';

const FeaturePanel = ({ component }) => {
  const { heading, feature } = component;
  const [activePanel, setActivePanel] = useState(0);

  const handleClick = useCallback(key => {
    setActivePanel(key);
    const activeImage = document.querySelector('.image-animation');
    if (activeImage) {
      activeImage.classList.remove('image-animation');
      // eslint-disable-next-line no-void
      void activeImage.offsetWidth;
      activeImage.classList.add('image-animation');
    }
  }, []);

  return (
    <PanelStyles className="App">
      <Container>
        <Row className="wrapper">
          <Col className="media" xs="24" sm="12" lg="6">
            <div className="feature-image px-4 px-md-0">
              {feature &&
                (feature[activePanel].image?.gatsbyImageData ? (
                  <OptimizedImage
                    alt={`${feature[activePanel].title}`}
                    image={`${feature[activePanel].image.gatsbyImageData}`}
                    title={`${feature[activePanel]?.image?.title}`}
                    className={`${
                      activePanel === feature[activePanel].key
                        ? 'image-animation'
                        : ''
                    }`}
                  />
                ) : (
                  <OptimizedImage
                    alt={`${feature[activePanel].title}`}
                    src={`${feature[activePanel].image.fluid.src}`}
                    title={`${feature[activePanel]?.image?.title}`}
                    className={`${
                      activePanel === feature[activePanel].key
                        ? 'image-animation'
                        : ''
                    }`}
                  />
                ))}
            </div>
          </Col>
          <Col className="text px-4 px-md-0" xs="24" sm="12" lg="6">
            <h1 className="heading">{heading}</h1>
            <FeatureWrapper>
              {feature &&
                feature.length > 0 &&
                feature.map(feat => (
                  <Feature
                    key={feat.key}
                    onClick={() => handleClick(feat.key)}
                    className={`${activePanel === feat.key && 'active'}`}
                  >
                    {feat.title}
                  </Feature>
                ))}
            </FeatureWrapper>
          </Col>
        </Row>
      </Container>
    </PanelStyles>
  );
};

export default FeaturePanel;
